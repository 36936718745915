import { Footer } from "../Footer/Footer";
import Select from "react-select";
import { Navigation } from "../Navigation/Navigation";
import { MintStyle } from "./MintStyle";
import { useContext } from "react";
import { TransactionContext } from "../../context/TransactionContext";
import soldout from "../../img/soldout.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";

export const Mint = (props) => {
  const {
    connectWallet,
    getamountMint,
    QuantityMint,
    Usermint,
    TotalSupply,
    cheker,
  } = useContext(TransactionContext);

  const connectwl = () => {
    connectWallet();
  };

  const checker = () => {
    cheker();
  };

  const handleSubmitMint = () => {
    const amount = QuantityMint;
    if (amount === 0) {
      return;
    } else {
      Usermint(amount);
    }
  };

  const [disabled, setDisabled] = useState(true);
  const handleClick = () => {
    setDisabled((prev) => !prev);
  };
  const optionsMint = [
    { value: 1, label: "1Mint" },
    { value: 2, label: "2Mint" },
    { value: 3, label: "3Mint" },
    { value: 4, label: "4Mint" },
    { value: 5, label: "5Mint" },
    { value: 6, label: "6Mint" },
    { value: 7, label: "7Mint" },
    { value: 8, label: "8Mint" },
    { value: 9, label: "9Mint" },
    { value: 10, label: "10Mint" },
    { value: 11, label: "11Mint" },
    { value: 12, label: "12Mint" },
  ];

  return (
    <MintStyle>
      <div>
        <Navigation
          handleLanguage={props.handleLanguage}
          language={props.language}
        ></Navigation>
        <div>
          <h1>{props.language.mint.title}</h1>
          <div className="mint-section activated">
            <button onClick={connectwl}>{props.language.mint.connect}</button>
          </div>
          <div className="mint-section activated">
            <button onClick={checker}>{props.language.mint.check}</button>
          </div>
          <div className="content">
            <div className="mint-image">
              <img src={soldout} alt="soldout" />
            </div>
            <div className="mint-context">
              <div className="text">
                <h3>{props.language.mint.header}</h3>
              </div>
              <div className="sold-amount">{TotalSupply}/2000</div>
              <div
                className={
                  disabled
                    ? "mint-section deactivated"
                    : "mint-section activated"
                }
              >
                <div className="mint-count">
                  <Select
                    options={optionsMint}
                    onChange={(e) => getamountMint(e.value)}
                  />
                </div>
                <div className="mint-eth">
                  <button onClick={handleSubmitMint} disabled={disabled}>
                    {props.language.mint.button1}
                  </button>
                </div>
              </div>
              <div className="consent">
                <input
                  type="checkbox"
                  checked={!disabled}
                  onChange={handleClick}
                />
                <label>
                  {props.language.mint.consent1}
                  <Link to={`/terms`}>{props.language.mint.terms}</Link>
                  {props.language.mint.consent2}
                  <span>*</span>
                </label>
                <p>{props.language.mint.consent3}</p>
              </div>
              <div className="og-mint">
                - OG mint : 0.025ETH 2 max per wallet & tx
              </div>
              <div className="whitelist-mint">
                - WL mint : 0.025ETH 2 max per wallet & tx
              </div>
              <div className="public-mint">
                - Public mint : 0.03ETH 3 max per wallet & tx
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </MintStyle>
  );
};
