import styled from "styled-components";

const FooterStyle = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: solid #383838 1px;
  font-size: 12px;
  .copyright {
    margin-top: 0.7rem;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: grey;
    }
  }
  @media screen and (max-width: 449px) {
    flex-direction: column;
    gap: 0.3rem;
  }
`;

const s = { FooterStyle, Links };

export default s;
