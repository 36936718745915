import { Footer } from "../Footer/Footer";
import { Navigation } from "../Navigation/Navigation";
import s from "./Legal.styles";

const content = [
  {
    header: "事業者名称",
    text: "Mark Door inc.\n代表取締役社長　松田 光弘",
  },
  { header: "事業者の所在地", text: "東京都港区南青山2-2-15" },
  { header: "事業者の連絡先", text: "ppp@markdoor.net" },
  { header: "営業時間", text: "９：００〜１７：００" },
  { header: "販売価格", text: "各商品のページに記載している価格とします。" },
  {
    header: "代金の支払方法・時期",
    text: "販売価格は、表示された金額（表示価格/消費税込）と致します。\nクレジットカード決済もしくは仮想通貨決済：ご注文時にお支払いが確定します。",
  },
  {
    header: "商品の届け時期",
    text: "お客様が決済完了後且つお客様が当社に、NFTのウォレットアドレスをご共有頂いてから7日以内にトランスファー（お届け）いたします。",
  },
  {
    header: "返品について",
    text: "商品に欠陥があると弊社が認めた場合を除き、基本的には返品には応じません。",
  },
];

export const Legal = (props) => {
  return (
    <s.PageWrapper>
      <Navigation
        handleLanguage={props.handleLanguage}
        language={props.language}
      ></Navigation>
      <s.BodyStyle>
        <s.ContentStyle>
          <h1>特定商取引法に基づく表記</h1>
          {content.map((e) => {
            return (
              <s.ItemsStyle key={e.header}>
                <h4>{e.header}</h4>
                <p>{e.text}</p>
              </s.ItemsStyle>
            );
          })}
        </s.ContentStyle>
      </s.BodyStyle>
      <Footer />
    </s.PageWrapper>
  );
};
