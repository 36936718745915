import s from "./TermsOfUse.styles";
import { Navigation } from "../Navigation/Navigation";
import { Footer } from "../Footer/Footer";
import { termsOfUseContent, utilitiesContent } from "./TermsOfUseContent";

const abstractText =
  "当該利用規約（以下「本規約」といいます。）には、Mark Door合同会社（以下「当社」といいます。）がご利用に当たりお客様に遵守していただくとともに、当社とお客様間の義務関係が定められております。お客様は当社のサービスを利用する際は当然に本規約を全文読み、全文理解した上で同意したものとみなします。";
export const TermsOfUse = (props) => {
  return (
    <s.PageWrapper>
      <Navigation
        handleLanguage={props.handleLanguage}
        language={props.language}
      ></Navigation>
      <s.BodyStyle>
        <s.ContentStyle>
          <h1>利用規約</h1>
          <p>{abstractText}</p>
          {termsOfUseContent.map((e) => {
            return (
              <s.ItemsStyle key={e.header1}>
                <h4>{e.header1}</h4>
                {e.content.map((element) => {
                  return <p key={element}>{element}</p>;
                })}
              </s.ItemsStyle>
            );
          })}
          <s.Signiture>
            <p>【2023年2月9日制定】</p>
            <p>Mark Door 合同会社</p>
            <p>代表社員　松田　光弘</p>
          </s.Signiture>
        </s.ContentStyle>
        <s.ContentStyle>
          <h1>ユーティリティ規約</h1>
          {utilitiesContent.map((e) => {
            return (
              <s.ItemsStyleUtilities key={e.content}>
                <p>{e.content}</p>
                {e.details.map((element, index) => {
                  return <p key={index}>{element}</p>;
                })}
              </s.ItemsStyleUtilities>
            );
          })}
        </s.ContentStyle>
      </s.BodyStyle>
      <Footer />
    </s.PageWrapper>
  );
};
