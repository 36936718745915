import { ethers } from 'ethers';
import { createContext, useState, useEffect } from 'react'
import { contractAddress } from '../contracts/connect'
import  TabenomicsABI  from '../contracts/Tabenomics.json'
import checkW from './checkerW.json'
import checkO from './checkerO.json'

export const TransactionContext = createContext();


let accounts;
const { ethereum } = window;

const getSmartContract = () =>{
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const transactonContract = new ethers.Contract(
        contractAddress,
        TabenomicsABI.abi,
        signer
    );

    //console.log(provider, signer, transactonContract);

    return transactonContract;
};

export const TransactionProvider = ({children}) =>{

    const [, setAccount] = useState('');
    const [, setChainId] = useState(false);
    const [QuantityMint, setQuantityMint] = useState(0);
    const [QuantityBurn, setQuantityBurn] = useState(0);
    const [TotalSupply, setTotalSupply] = useState("-");
    //const [ChekerWl, setChekerWl] = useState(0);
    //const [ChekerOg, setChekerOg] = useState(0);
    const ChainId = '0x1';

    const checkMetaMaskInstalled = async () => {
        if (!ethereum) {
          alert('Please install the MetaMask');
        }
    }
    
    const checkChainId = async () => {
        if (ethereum) {
          const chain = await ethereum.request({
            method: 'eth_chainId'
          });
          //console.log(`chain: ${chain}`);
          if (chain !== ChainId) {
            try {
              await ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{
                      chainId: '0x1'
                  }],
              });
          } catch (err) {
              // This error code indicates that the chain has not been added to MetaMask.
              console.log(err)
          }
            setChainId(false)
            
            return
          } else {
            console.log("Already connected to ethereum mainnet...");
            setChainId(true)
          }
        }
    }
    
    const connectWallet = async () => {
        try {
          accounts = await ethereum.request({
            method: 'eth_requestAccounts'
        });
        await checkMetaMaskInstalled();
        //await console.log(`account: ${accounts[0]}`);
        await setAccount(accounts[0]);
        await getSmartContract();
        await checkChainId();
        await alert("connect success");
        PushTotalSupply();
        } catch (err) {
          console.log(err);
        }
    };

    const PushTotalSupply = async() => {
      const transactonContract =  getSmartContract();
      const totalsupply = await transactonContract.totalSupply();
      const push = 1500 - totalsupply;
      setTotalSupply(push);
    };

    const cheker = async() => {
      try {
        accounts = await ethereum.request({
          method: 'eth_requestAccounts'
      });
      await checkMetaMaskInstalled();
      //await console.log(`account: ${accounts[0]}`);
      await setAccount(accounts[0]);
      await getSmartContract();
      await checkChainId();
      PushTotalSupply();
      //const check = await transactonContract.checker(accounts);
      //console.log(check);
      } catch (err) {
        console.log(err);
      }
      var jsonDataW = checkW;
      var jsonDataO = checkO;
      if (jsonDataW[accounts[0]] === undefined && jsonDataO[accounts[0]] === undefined){
        alert("you wallet is not OG and WL(あなたのウォレットはOGとWL、ともに登録されていません)");
      }else if(jsonDataW[accounts[0]] === undefined){
        alert("you wallet is OG only. you can " + jsonDataO[accounts[0]]*2 + "mint (ウォレットはOGのみ登録されています OGセールで" + jsonDataO[accounts[0]]*2 + " 枚ミント可能です)");
      }else if( jsonDataO[accounts[0]] === undefined){
        alert("you wallet is WL only. you can " + jsonDataW[accounts[0]]*2 + "mint (ウォレットはWLのみ登録されています 先行販売で" + jsonDataW[accounts[0]]*2 + " 枚ミント可能です)");
      }else{
        alert("you wallet is OG and WL. you can " + jsonDataO[accounts[0]]*2 + "OGmint and " + jsonDataW[accounts[0]]*2 + "WLmint (ウォレットはOGとWLに登録されています OGセールで" + jsonDataO[accounts[0]]*2 + " 枚ミント、先行販売で" + jsonDataW[accounts[0]]*2 + "枚ミント可能です)");
      }
    };

    const Usermint = async(getquantity) => {
      const transactonContract =  getSmartContract();
      const saleprice = await transactonContract.getTime();
      accounts = await ethereum.request({
        method: 'eth_requestAccounts'
      });
      //const totalsupply = await transactonContract.totalSupply();
      const puresale = 0.025
      const publicsale = 0.03
      const saleStartTime1st = 1677582000; // 2023/02/28 20:00:00 JST
      const saleStartTime2nd = 1677583800; // 2023/02/28 20:30:00 JST
      const saleStartTimePublic = 1677592800; // 2023/02/28 23:00:00 JST
      let jsonDataW = checkW;
      let jsonDataO = checkO;
      let user_sta = 0;
      let ethvalue
        if(saleprice < saleStartTime2nd && saleprice > saleStartTime1st){
          ethvalue = String(puresale * getquantity)
 
          if (jsonDataO[accounts[0]] === undefined){
            user_sta = 0
          }else{
            user_sta = jsonDataO[accounts[0]]
          }

        }else if(saleprice < saleStartTimePublic && saleprice > saleStartTime2nd){
          ethvalue = String(puresale * getquantity)

          if (jsonDataW[accounts[0]] === undefined){
            user_sta = 0
          }else{
            user_sta = jsonDataW[accounts[0]]
          }

        }else{
          ethvalue = String(publicsale * getquantity)
          user_sta = 0
        }

      try {
        const transactionHash = await transactonContract.MintUser(getquantity, user_sta , {value: ethers.utils.parseUnits(ethvalue)});
        //alert(`Mint Now Loading・・・`);
        console.log(`Mint Now Loading・・・${transactionHash.hash}`);
        await transactionHash.wait ();
        alert(`Mint Success`);
        console.log(`Mint Success${transactionHash.hash}`);
        PushTotalSupply();
      } catch( e ) {
        alert(e.reason);
      }
    }

    const Userburn = async(getquantity) => {
      const transactonContract =  getSmartContract();

      try {
        const transactionHash = await transactonContract.UserBurn(getquantity);
        alert(`Burn Now Loading・・・${transactionHash.hash}`);
        await transactionHash.wait ();
        alert(`Burn Success${transactionHash.hash}`);
      } catch( e ) {
        alert(e.reason);
      }
    }

    const getamountMint =  (getquantity) =>{
      setQuantityMint(getquantity);
      //console.log(QuantityMint);
    }

    const getamountBurn =  (getquantity) =>{
      setQuantityBurn(getquantity);
      //console.log(QuantityBurn);
    }

    useEffect(() => {
      //connectWallet();
      PushTotalSupply();
    }, []);

    return(
        <TransactionContext.Provider value={{connectWallet, getamountMint, QuantityMint, Usermint, getamountBurn, QuantityBurn, Userburn, TotalSupply, cheker}}>{children}</TransactionContext.Provider>
    )
};
