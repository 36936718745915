export const termsOfUseContent = [
  {
    header1: "第1条 （適用）",
    content: [
      "1. 本規約は、お客様と当社との間の本サービス及び本サービスに付随して顧客が行使する一切の権利（ここでの一切の権利には、本サービスに付随するユーティリティ等も含む。但しこれに限らない。以下、これらを併せて「本サービス等」という。）の利用に関わる一切の関係に適用されるものとします。",
      "2. 当社は本サービス等に関し、本規約のほか、ご利用にあたってのルール等、各種規定（以下、「個別規定」といいます。）することがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。",
      "3. 本規約の定めが前項の個別規定の定めと矛盾する場合には、個別規定において特段の定めなき限り、個別規定の定めが優先されるものとします。",
      "4. 本サービス等は、当社が提供するウェブサイト（https://tabenomics.com/及び同サイトよりリンクするウェブサイトをいい、以下、「本ウェブサイト」といいます。）を通じて、当社から、お客様に対し、NFT（Non-Fungible Token）（ブロックチェーン技術を⽤いてブロックチェーン上で発行されるトークンのうち、固有の値や属性を持たせた代替性のないトークンをいいます。）を販売するサービスです（以下、本サービス等上で販売される NFT を「本NFT」といいます。）。",
      "5. お客様が未成年者の場合は、本サービス等の利用及び本規約内容について、法定代理人（親権者等）の承認を得た上で本サービス等を利用するものとします。",
    ],
  },
  {
    header1: "第2条 （ウォレットの利用）",
    content: [
      "1. 本サービス等を利用して本NFTを入手するためには、ウェブサイトにて決済を行ったうえで、ウォレット（NFTを保管、管理、閲覧等するためのツールをいいます。）をお客様が自ら保有している必要があります。",
      "2. お客様は、本サービス等の利⽤にあたり、自身のウォレットに関する情報、その他本サービス等の利用に伴い使用する⼀切の情報（以下「ウォレット情報等」といいます。）を、不正に利用されないよう自らの責任において適切に管理するものとします。",
      "3. 当社は、ウォレット情報等が第三者に利用されたことによってお客様その他第三者に生じた損害については、いかなる場合においても一切責任を負いません。",
      "4. 当社は、ウォレット情報等を使用してなされた行為を、当該ウォレット情報等を管理すべきお客様による行為とみなすことができるものとします。",
    ],
  },
  {
    header1: "第3条 （売買契約）",
    content: [
      "1. お客様は、当社所定の方法により本NFTの購入を申し込むものとし、本ウェブサイト上又は本サービス等の公式Teitter ,公式Discord 等上に表示された本NFTの価格に相当するETHその他の暗号資産、日本円その他（以下、「通貨一式」という。）を当社が定めるアドレスに送付し、当社が当該通貨一式を現実に受領した時点で、本NFTに関する売買契約（以下「本契約」といい、本契約におけるお客様を「購入者」とします。）が成立するものとします。",
      "2. 当社は、購入者（本NFTが第三者に譲渡された場合は当該第三者。以下本項において同じ。）に対し、当該購入者が購入した本NFTについて、以下の各号に定める行為を行うことを許諾します。",
      "① 本NFTに紐づけられた文章、画像、プログラムその他のコンテンツ（以下、「コンテンツ」といいます。）にアクセスし、鑑賞し、または、私的使用目的で複製すること",
      "② 本NFTに紐づけられたコンテンツを個人のSNSアカウントに投稿し、公開すること",
      "③ 本NFTを第三者に譲渡すること",
      "④ 前各号の他、個別規定で定めた行為、及び当社が明示的に許諾した行為",
      "3. 購入者は、第三者に対し、本NFTを譲渡する場合、当該第三者に対し、本規約及び個別規定に定める条件と同等の条件を課すものとし、また、購入者は、当該譲渡後は前項の許諾に基づく権利を失うものとします。",
      "4. 本NFTの購入に際して必要となる、ブロックチェーンネットワークを利用する際に⽀払う手数料（以下、「ガス代」といいます。）は、お客様が負担するものとします。トランザクションエラー、ブロックチェーンネットワークの不具合その他当社の責めに帰さない事由により、当社が通貨一式を受領できなかった場合にお客様が支出したガス代についても同様とします。",
      "5. 当社は、購入者に対し、ブロックチェーン上に本NFT及びその購入に係る⼀定の記録をし、購入者のウォレットアドレスに本NFTを送付するものとし、これをもって当社による本契約の履行が完了するものとします。",
      "6. 購入者は、本契約等成立後は、法律に定め（強行法規に限る。）がある場合を除き、いかなる理由があっても成立した本契約の無効または取消しを主張しないものとし、当社は、返品及び返金には応じないものとします（クーリングオフ適用外）。",
    ],
  },
  {
    header1: "第4条 （知的財産権）",
    content: [
      "本サービス等によって提供されるコンテンツ（本NFTに紐づけられたコンテンツを含む。）の著作権その他の知的財産権は、当社又はコンテンツ提供者などの正当な権利者に帰属しています。また当社による本サービス等の利⽤を許す行為、本契約の締結及び購入者のウォレットアドレスに本NFTを送付する行為は、これらの権利の移転とは解釈されないものとします。",
    ],
  },
  {
    header1: "第5条 （禁止事項）",
    content: [
      "お客様は、本サービス等の利用にあたり、以下の行為をしてはならないものとします。",
      "① 法令または公序良俗に違反する行為",
      "② 犯罪行為若しくは犯罪に結びつく行為またはこれらの行為を援助若しくは助長する行為",
      "③ 本サービス等に含まれる著作権、商標権その他の知的財産権を侵害する行為",
      "④ 当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為",
      "⑤ 本サービス等によって得られた情報（本NFT及びコンテンツを含む。）を商業的に利用する行為",
      "⑥ 当社のサービスの運営を妨害するおそれのある行為",
      "⑦ 不正アクセスをし、またはこれを試みる行為",
      "⑧ 他のお客様に関する個人情報等を収集または蓄積する行為",
      "⑨ 他のお客様に成りすます行為",
      "⑩ 当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為",
      "⑪ マネーロンダリング、資金洗浄等の目的とする一切の行為",
      "⑫ その他、当社が不適切と判断する行為",
    ],
  },
  {
    header1: "第6条 （保証の否認および免責事項）",
    content: [
      "1. 当社は、本サービス等に事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを保証しておりません。",
      "2. 当社は、本サービス等によってお客様に生じたあらゆる損害について、一切の責任を負いません。ただし、本サービス等に関する当社とお客様との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されませんが、この場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりお客様に生じた損害のうち特別な事情から生じた損害（当社またはお客様が損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。",
      "3. 当社は、本サービス等に関して、お客様と他のお客様または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。",
      "4. 当社は、何らかの理由により本サービス等を運営できなくなる場合、お客様の本サービス等におけるあらゆる権利を消滅することに同意したものとみなします。",
    ],
  },
  {
    header1: "第7条 （サービスの変更、中止、終了等）",
    content: [
      "1. 当社は、本サービス等の内容を事前の予告なく変更・追加・削除することができるものとし、お客様は変更・追加・削除したと同時に当該変更・追加・削除内容に無条件で同意したものとみなします。なお、内容の変更等に関する情報は、原則として本ウェブサイト等に掲載します。",
      "2. 当社は、以下各号のいずれかに該当する場合には、自らの判断で、本サービス等の全部又は一部の提供を中止又は中断することができます。この場合、当社は合理的な方法でお客様にその旨を通知するものとしますが、緊急に中断する必要が生じた場合等、やむを得ない事情により通知できない場合は、この限りではありません。",
      "① 天災地変等の不可抗力、通信回線等の障害、過度なアクセス集中、不正アクセス等により、本サービス等の提供ができない場合",
      "② 本サービス等の提供にかかるシステムの点検、保守等の作業を行う場合",
      "③ その他円滑な本サービス等の提供のために、当社が必要と判断した場合",
      "3. 当社は、本ウェブサイト上に情報を掲載することにより、本サービス等の提供を終了することができます。",
    ],
  },
  {
    header1: "第8条 （反社会的勢力の排除）",
    content: [
      "お客様は、当社に対し、以下各号に定める事項を誓約するものとします。",
      "① 自ら及び自らの重要な取引先が暴力団、暴力団関係企業、政治活動標ぼうゴロ、特殊知能暴力集団、組織的犯罪集団及びこれらに準ずる団体並びにこれらの構成員等（以下総称して「反社会的勢力」という。）ではないこと。",
      "② 反社会的勢力が、直接であること間接であることを問わず自らの経営に関与していないこと。",
      "③ 名目を問わず反社会的勢力に資金提供を行っておらず、又は反社会的勢力であることを知りながら取引又は交流をもっていないこと。また、今後もこれらを行わないこと。",
      "④ その他反社会的勢力との間で社会的に非難されるべき関係を有していないこと。",
      "⑤ 自ら又は第三者を利用した、脅迫的な言動又は暴力を用いた要求行為、偽計又は威力を用いた業務妨害又は信用を毀損する行為及び当社又は第三者に対する法的な責任を超えた不当な要求行為を行わないこと。",
    ],
  },
  {
    header1: "第9条 （権利義務の譲渡の禁止）",
    content: [
      "お客様は、当社の書面による事前の承諾なく、本契約上の地位または本契約若しくは本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。",
    ],
  },
  {
    header1: "第10条 （規約の変更）",
    content: [
      "1. 当社は、民法第548条の4の規定に基づき、本規約を変更できるものとします。",
      "2. 当社は、本規約を変更する場合、本規約を変更する旨、変更内容および効力発生時期を本ウェブサイトへの掲載による公表その他適切な方法により周知するものとし、当該効力発生時期を経過した後にお客様が本サービス等を利用した場合、本規約の変更に同意したものとみなします。",
    ],
  },
  {
    header1: "第11条 （準拠法・裁判管轄）",
    content: [
      "1. 本規約の解釈にあたっては、日本法を準拠法とします。",
      "2. 本規約または本契約に起因または関連して生じた訴訟その他の⼀切の紛争については、東京地方裁判所を第⼀審の専属的合意管轄裁判所とします。",
    ],
  },
  {
    header1: "第12条 （本サービス等）",
    content: [
      "当社は、本サービス等を別紙の「ユーティリティ規約」の通り定め、7条の規定に基づき、内容を事前の予告なく変更・追加・削除します。お客様は変更・追加・削除したと同時に当該変更・追加・削除内容に無条件で同意したものとみなします。",
    ],
  },
  {
    header1: "第13条 （本サービス等利用に際して）",
    content: [
      "当社は、本規約1条2項及び3項の規定により、お客様がユーティリティの行使をすることができるのは、事前にお客様自身が反社会的勢力でない旨の声明を出し、且つ面談等の審査により当社が行使を許諾した者のみとするなど（なお、個別規定はこれに限らず。）の個別規定を定めます。",
    ],
  },
];

export const utilitiesContent = [
  {
    content:
      "1. 本規約は、お客様と当社との間の本サービス及び本サービスに付随して顧客が行使する一切の権利（ここでの一切の権利には、本サービスに付随するユーティリティ等も含む。但しこれに限らない。以下、これらを併せて「本サービス等」という。）の利用に関わる一切の関係に適用されるものとします。",
    details: [],
  },
  {
    content: "2. ユーティリティの内容は下記の通りとします。",
    details: [
      "①NEO HOOD ANIMALのNFTを規定枚数（30万円分相当）burnすることでタレントの彦摩呂をイベント等に招待することができます。但し、事前にお打ち合わせをさせていただき、登壇可能かどうかを判断させていただきます。（反社チェックも含める）また、こちらはリリース日から180日後までを有効期間とし、本ユーティリティを一回使用いただくごとに1.2倍必要枚数が上昇するように設定しております。",
      "②NEO HOOD ANIMALのNFTを規定枚数burnすることで飲食業者様やその他事業者様は本チームの所定の販売促進支援を受けることができます。これは「Tips」や、事業者NFTの発行や、ブロックチェーンを利用した事業化支援を無場合があります。",
      "③NEO HOOD ANIMALのNFT所有者限定のオフ会に参加することが可能です。オフ会の種類によっては予め個人情報の事前開示や、参加費の負担頂いた上での参加になる場合もございます。",
      "④NEO HOOD ANIMALのNFTホルダー限定の「彦摩呂さんの美味しいお店紹介」をするDiscordのチャットグループを閲覧することができます。",
      "⑤また、随時更新されるユーティリティや、開発プロダクトを利用できます。",
    ],
  },
  {
    content:
      "3. ユーティリティの内容変更、及び提供期間の変更については運営側で随時変更可能とします。",
    details: [],
  },
  {
    content: "4. ユーティリティ規約の用語を下記に定める。",
    details: [
      "①「burn」とは、NFTの権利行使等の理由により消滅することを言います。",
      "②「Tips」とは、本サービスの運営業者であるMark Door同業会社が運営するマーケティングツールを指します。",
      "③「オフ会」とは、会員の方限定で参加することができる懇親会のことを指します。",
    ],
  },
];
