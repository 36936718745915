import styled from "styled-components";

export const MintStyle = styled.div`
  min-height: 100vh;
  -webkit-appearance: none;
  -moz-appearance: none;
  > div {
    padding-bottom: 50px;
  }
  h1 {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;
  }
  .mint-image {
    img {
      width: 500px;
    }
  }
  .mint-context {
    margin: auto 50px;
  }
  .sold-amount {
    margin: 50px auto;
    font-size: 25px;
  }
  .mint-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0 auto;
  }
  .mint-section button {
    font-size: 20px;
    text-align: center;
    padding: 10px 20px;
    border-radius: 50px;
  }
  .activated button:hover {
    background-color: #ffffffc5;
    border: #c2c2c2 2px solid;
    border-radius: 50px;
  }
  .activated button:active {
    background-color: grey;
    border: grey solid 2px;
    border-radius: 50px;
  }
  .whitelist-mint {
    margin: 10px auto;
  }
  .public-mint {
    margin: 10px auto;
  }
  .mint-count {
    margin: 20px;
    color: black;
  }
  .consent {
    font-size: 1rem;
    input:hover {
      cursor: pointer;
    }
    span {
      color: red;
    }
    a {
      color: white;
    }
    p {
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 1175px) {
    .content {
      display: block;
      margin-left: 100px;
      margin-right: 100px;
      margin-top: 50px;
    }
    .mint-context {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  @media screen and (max-width: 699px) {
    .mint-image {
      img {
        width: 350px;
      }
    }
    .content {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 499px) {
    .mint-image {
      img {
        width: 300px;
      }
    }
    .mint-section {
      display: block;
    }
    .mint-section button {
      margin: 10px auto;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 349px) {
    .mint-image {
      img {
        width: 200px;
      }
    }
  }
`;
