import s from "./PrivacyPolicy.styles";
import { privacyPolicyContent } from "./PrivacyPolicyContent";
import { Navigation } from "../Navigation/Navigation";
import { Footer } from "../Footer/Footer";

const abstractText =
  "本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）、もしくは個人識別符号が含まれる情報を意味するものとします。";

export const PrivacyPolicy = (props) => {
  return (
    <s.PageWrapper>
      <Navigation
        handleLanguage={props.handleLanguage}
        language={props.language}
      ></Navigation>
      <s.BodyStyle>
        <s.ContentStyle>
          <h1>プライバシーポリシー</h1>
          <p>{abstractText}</p>
          {privacyPolicyContent.map((e) => {
            return (
              <s.ItemsStyle key={e.header1}>
                <h4>{e.header1}</h4>
                {e.content.map((element) => {
                  return <p key={element}>{element}</p>;
                })}
              </s.ItemsStyle>
            );
          })}
        </s.ContentStyle>
      </s.BodyStyle>
      <Footer />
    </s.PageWrapper>
  );
};
