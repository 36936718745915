import styled from "styled-components";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Mint } from "./components/Mint/Mint";
import { Home } from "./components/Home/Home";
import { useState } from "react";
import textJson from "./components/text.json";
import { Legal } from "./components/Legal/Legal";
import { ScrollToTop } from "./components/ScrollToTop";
import { PrivacyPolicy } from "./components/PrivacyPolicy/PrivacyPolicy";
import { TermsOfUse } from "./components/TermsOfUse/TermsOfUse";

const AppStyle = styled.div`
  background-color: black;
  color: white;
  /* font: "Sequel Sans Display Medium"; */
  font-size: 1.1rem;
`;

function App() {
  const text = textJson.text;
  const [japanese, setJapanese] = useState(false);
  const handleLanguage = () => {
    setJapanese((lang) => !lang);
  };
  return (
    <AppStyle>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    handleLanguage={handleLanguage}
                    language={japanese ? text.jp : text.en}
                  />
                }
              />
              <Route
                path={`/mint/`}
                element={
                  <Mint
                    handleLanguage={handleLanguage}
                    language={japanese ? text.jp : text.en}
                  />
                }
              />
              <Route
                path={`/legal/`}
                element={
                  <Legal
                    handleLanguage={handleLanguage}
                    language={japanese ? text.jp : text.en}
                  />
                }
              />
              <Route
                path={`/privacypolicy/`}
                element={
                  <PrivacyPolicy
                    handleLanguage={handleLanguage}
                    language={japanese ? text.jp : text.en}
                  />
                }
              />
              <Route
                path={`/terms/`}
                element={
                  <TermsOfUse
                    handleLanguage={handleLanguage}
                    language={japanese ? text.jp : text.en}
                  />
                }
              />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </AppStyle>
  );
}

export default App;
