export const privacyPolicyContent = [
  {
    header1: "1. 個人情報の定義",
    content: [
      "本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）、もしくは個人識別符号が含まれる情報を意味するものとします。",
    ],
  },
  {
    header1: "2. 個人情報の利用目的",
    content: [
      "当ショップは、お客様の個人情報を、以下の目的で利用致します。",
      "（１） 当ショップサービスの提供のため",
      "（２） 当ショップサービスに関するご案内、お問い合わせ等への対応のため",
      "（３） 当ショップの商品、サービス等のご案内のため",
      "（４） 当ショップサービスに関する当ショップの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため",
      "（５） 当ショップサービスに関する規約等の変更などを通知するため",
      "（６） 当ショップサービスの改善、新サービスの開発等に役立てるため",
      "（７） 当ショップサービスに関連して、個別を識別できない形式に加工した統計データを作成するため",
      "（８） その他、上記利用目的に付随する目的のため",
    ],
  },
  {
    header1: "3. 個人情報利用目的の変更",
    content: [
      "当ショップは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。",
    ],
  },
  {
    header1: "4. 個人情報利用の制限",
    content: [
      "当ショップは、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。",
      "（１） 法令に基づく場合",
      "（２） 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき",
      "（３） 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき",
      "（４） 国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき",
    ],
  },
  {
    header1: "5. 個人情報の適正な取得",
    content: [
      "当ショップは、適正に個人情報を取得し、偽りその他不正の手段により取得しません。",
    ],
  },
  {
    header1: "6. 個人情報の安全管理",
    content: [
      "当ショップは、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当ショップの従業員に対し、必要かつ適切な監督を行います。また、当ショップは、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。",
    ],
  },
  {
    header1: "7. 第三者提供",
    content: [
      "当ショップは、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめお客様の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。",
      "（１） 当ショップが利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合",
      "（２） 合併その他の事由による事業の承継に伴って個人情報が提供される場合",
      "（３） 個人情報保護法の定めに基づき共同利用する場合",
    ],
  },
  {
    header1: "8. 個人情報の開示",
    content: [
      "当ショップは、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当ショップが開示の義務を負わない場合は、この限りではありません。",
    ],
  },
  {
    header1: "9. 個人情報の訂正等",
    content: [
      "当ショップは、お客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、お客様ご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当ショップが訂正等の義務を負わない場合は、この限りではありません。",
    ],
  },
  {
    header1: "10. 個人情報の利用停止等",
    content: [
      "当ショップは、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨をお客様に通知します。但し、個人情報保護法その他の法令により、当ショップが利用停止等の義務を負わない場合は、この限りではありません。",
    ],
  },
  {
    header1: "11. Cookie（クッキー）その他の技術の利用",
    content: [
      "（１） 当ショップのサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当ショップによる当ショップのサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当ショップのサービスの一部の機能をご利用いただけなくなる場合があります。",
      "（２） 当ショップは、当ショップサービスが提供するサービスの利用状況等を調査・分析するため、本サービス上に Google LLCが提供する Google アナリティクスを利用しています。Googleアナリティクスでデータが収集、処理される仕組みその他Googleアナリティクスの詳しい情報につきましては、同社のサイトをご覧ください。Google アナリティクス 利用規約：    https://www.google.com/analytics/terms/jp.html    お客様が Google パートナーのサイトやアプリを使用する際の Google によるデータ使用：https://www.google.com/intl/ja/policies/privacy/partners/Google プライバシーポリシー：http://www.google.com/intl/ja/policies/privacy/   なお、お客様はご自身のデータが Google アナリティクスで使用されることを望まない場合は、Google 社の提供する Google アナリティクス オプトアウト アドオンをご利用ください。Google アナリティクス オプトアウト アドオン：https://tools.google.com/dlpage/gaoptout",
      "（３） 本サービスでは「Google Analyticsの広告向けの機能」を有効にしており、下記の機能を利用し、広告やサイト改善のためDoubleClick CookieなどのサードパーティCookieを利用しています。Google AnalyticsリマーケティングGoogle Analyticsのユーザー属性レポートとユーザー属性レポートとインタレスト レポートこれにより、本サービスではGoogle AnalyticsのCookieを利用して、お客様の年齢・性別・閲覧履歴・本サービスに関する関心の傾向をおおよそ把握するための分析が可能となっております。    「Google Analyticsの広告向けの機能」を使用されることを望まない場合は、設定によってトラッキングを無効にすることが可能です。Google Analytics オプトアウト アドオンをブラウザにインストールされると無効にすることができます。",
    ],
  },
  {
    header1: "12. お問い合わせ",
    content: [
      "開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、当ショップの「特定商取引法に基づく表記」内にある連絡先へご連絡いただくか、ショップページ内のお問い合わせフォームよりお問い合わせください。",
    ],
  },
  {
    header1: "13. 継続的改善",
    content: [
      "当ショップは、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。",
    ],
  },
];
