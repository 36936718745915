import styled from "styled-components";

const PageWrapper = styled.div``;

const BodyStyle = styled.div`
  background-color: black;
  min-height: calc(100vh - 90px);
`;

const ContentStyle = styled.div`
  margin: auto 280px;
  padding-bottom: 50px;
  h1 {
    font-weight: lighter;
  }
  > p {
    text-align: justify;
    text-indent: 2rem;
  }
  @media screen and (max-width: 1100px) {
    margin: auto 200px;
  }
  @media screen and (max-width: 850px) {
    margin: auto 100px;
  }
  @media screen and (max-width: 630px) {
    margin: auto 50px;
    h1 {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 510px) {
    h1 {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 410px) {
    margin: auto 30px;
  }
  @media screen and (max-width: 410px) {
    h1 {
      font-size: 20px;
    }
  }
`;

const ItemsStyle = styled.div`
  text-align: start;
  h4 {
    margin-bottom: 0;
    margin-top: 30px;
  }
  p {
    padding-left: 50px;
    margin-top: 10px;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  @media screen and (max-width: 510px) {
    p {
      padding-left: 0;
      font-size: 0.8em;
    }
  }
`;

const s = { PageWrapper, BodyStyle, ContentStyle, ItemsStyle };

export default s;
