import { Link } from "react-router-dom";
import s from "./FooterStyle";

export const Footer = () => {
  return (
    <s.FooterStyle>
      <s.Links>
        <Link to={`/legal/`}>特定商取引法に基づく表記</Link>
        <Link to={`/privacypolicy/`}>プライバシーポリシー</Link>
        <Link to={`/terms/`}>利用規約</Link>
      </s.Links>
      <div className="copyright">Copyright © NEO HOOD ANIMAL</div>
    </s.FooterStyle>
  );
};
